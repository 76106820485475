.wave {
    font-size: 30px;
    text-align: center;
    padding-left: 12px;
    animation-name: wave-animation;
    animation-iteration-count: infinite;
    animation-duration: 2.5s;
    transform-origin: 70% 70%;
    display: inline-block;
}

.smooth-loading {
  opacity: 0;
  transition: all 0.75s ease;
  -webkit-transition: all 0.75s ease;
  -moz-transition: all 0.75s ease;
  -ms-transition: all 0.75s ease;
  -o-transition: all 0.75s ease;
}

@keyframes wave-animation {
    0% {
        transform: rotate( 0.0deg)
    }
    10% {
        transform: rotate(14.0deg)
    }
    /* The following five values can be played with to make the waving more or less extreme */
    20% {
        transform: rotate(-8.0deg)
    }
    30% {
        transform: rotate(14.0deg)
    }
    40% {
        transform: rotate(-4.0deg)
    }
    50% {
        transform: rotate(10.0deg)
    }
    60% {
        transform: rotate( 0.0deg)
    }
    /* Reset for the last half to pause */
    100% {
        transform: rotate( 0.0deg)
    }
}